import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import './tabs.scss';

const Tabs = ({
  className,
  activeIndex = 0,
  items,
  onClick,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(activeIndex);

  useEffect(() => {
    if (activeIndex !== activeTabIndex) {
      setActiveTabIndex(activeIndex);
    }
  }, [activeIndex]);

  return (
    <section className={cx("tabs", className && className)}>
      {items?.length > 1 && <div className="tabs_tabList" role="tablist">
        {items?.map((item, index) => (
          <button
            className={cx(
              "item",
              activeTabIndex === index && "tabList_item__active"
            )}
            disabled={item?.disabled}
            key={`tabs-nav-item-${index}`}
            role="tab"
            onClick={() => {
              if (!item?.disabled) {
                onClick && onClick(index);
                setActiveTabIndex(index);
              }
            }}
          >
            {item?.label}
          </button>
        ))}
      </div>}
      <div className={cx("tabs_content", items?.length > 1 && "tabs_content__padding")}>
        {items?.map((item, index) => (
          <div
            hidden={activeTabIndex !== index}
            key={`tabs-content-item-${index}`}
            role="tabpanel"
            className={cx("tab_content", activeTabIndex === index && "fade-in")}
          >
            {activeTabIndex === index && item?.children}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Tabs;
