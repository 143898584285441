import React from "react"
import "./architecture.scss"

const Architecture = props => {
  const { title, list } = props

  return (
    <section className="architecture">
      <h2 className="architecture_title">{title.text}</h2>

      <div className="architecture_underline">
        <div className="line"></div>
      </div>

      <div className="architecture_list">
        {list.map((item, index) => (
          <figcaption key={`architecture-${index}`} className="item">
            <img
              className="item_icon"
              src={item.architecture_icon.url}
              alt={item.architecture_icon.alt}
            />
            <h3 className="item_name">{item.architecture_name}</h3>
            <div className="item_line"></div>
            <p className="item_description">{item.architecture_description}</p>
          </figcaption>
        ))}
      </div>
    </section>
  )
}

export default Architecture
