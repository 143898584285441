import React from "react"
import cx from "classnames"
import "./feature.scss"

const Feature = props => {
  const { className, description, image, imageLeft, items, title } = props
  return (
    <section className={cx("feature", className && className)}>
      <div
        className={cx("feature_content", imageLeft && "feature_content__right")}
      >
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
        <ul className="list">
          {items.richText.map((item, index) => (
            <li className="list_item" key={`item-${index}`}>
              {item.text}
            </li>
          ))}
        </ul>
      </div>
      <figcaption
        className={cx("featureItem", imageLeft && "featureItem__left")}
      >
        <img className="featureItem_image" src={image.url} alt={image.alt} />
      </figcaption>
    </section>
  )
}

export default Feature
