import React from "react"
import cx from "classnames"
import HomeSubtitleUnderline from "../../../../home-subtitle-underline/HomeSubtitleUnderline"
import HomeSubtitle from "../../../../home-subtitle/HomeSubtitle"
import "./moduleItem.scss"

const ModuleItem = props => {
  const { 
    className, 
    description, 
    image, 
    imageLeft, 
    items, 
    title, 
    category,
  } = props

  return (
    <section className={cx("moduleItem", className && className)}>
      <div
        className={cx("moduleItem_content", imageLeft && "moduleItem_content__right")}
      >
        <HomeSubtitle className="categorySubtitle" category={category} />
        <HomeSubtitleUnderline className="title" withoutLine>{title?.richText[0]}</HomeSubtitleUnderline>
        <p className="description">{description}</p>
        <ul className="list">
          {items.richText.map((item, index) => (
            <li className="list_item" key={`item-${index}`}>
               <div>{item.text}</div>
            </li>
          ))}
        </ul>
      </div>
      <figcaption
        className={cx("moduleItem_boxImage", imageLeft && "moduleItem_boxImage__left")}
      >
        <img className="image" src={image.url} alt={image.alt} />
      </figcaption>
    </section>
  )
}

export default ModuleItem
