import React from "react"
import cx from "classnames"
import { transformText } from "../../../utils/text.util";
import ButtonAnchor from "../../button-anchor/ButtonAnchor"
import "./hero.scss"

const Hero = props => {
  const {
    image,
    className,
    ctaText,
    description,
    name,
    phrase,
    setShowModal
  } = props

  return (
    <section className={cx("hero", className && className)}>
      <div className="hero_content">
        <div className="heroMain">
          <h1 className="heroMain_title">{transformText(name?.richText[0], "titleAccent")}</h1>
          <h2 className="heroMain_title">{transformText(phrase?.richText[0], "titleAccent")}</h2>
          <div className="heroMain_line" />
        </div>
        <div className="heroDetails">
          <p className="heroDetails_description">{transformText(description?.richText[0], "descriptionBold")}</p>
          <ButtonAnchor accent isButton onClick={()=>{setShowModal(true)}}>{ctaText}</ButtonAnchor>
        </div>
      </div>
      <div className="hero_background" style={{backgroundImage: `url(${image?.url})`}}/>
    </section>
  )
}

export default Hero
