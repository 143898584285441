import React, { useContext, useEffect, useState } from "react"
import cx from "classnames"
import { graphql } from "gatsby"
import LanguageContext from "../contexts/language"
import Footer from "../components/footer/Footer"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header/Header"
import Hero from "../components/productDetail/hero/Hero"
import HomeDualContent from "../components/home-dual-content/HomeDualContent"
import Benefits from "../components/productDetail/benefits/Benefits"
import Feature from "../components/productDetail/feature/Feature"
import Module from "../components/productDetail/module/Module"
import Architecture from "../components/productDetail/architecture/Architecture"
import RegisterForm from "../components/form/RegisterForm"
import FormContact from "../components/form-contact/FormContact"
import FeatureModule from "../components/productDetail/feature-module/FeatureModule"
const isBrowser = typeof window !== "undefined"

const ProductDetail = props => {
  const { data, uid } = props.data.prismicProductDetail

  const { theme, accesibilitySize } = useContext(LanguageContext)
  const [showModal, setShowModal] = useState(false)
  const [themeState, setThemeState] = useState(theme)
  const [accesibilitySizeState, setAccesibilitySizeState] = useState(
    accesibilitySize
  )

  useEffect(() => {
    if (isBrowser && themeState) localStorage.setItem("foris_theme", themeState)
  }, [themeState])

  useEffect(() => {
    if (isBrowser && accesibilitySizeState)
      localStorage.setItem("accesibility_size", accesibilitySizeState)
  }, [accesibilitySizeState])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout
      {...props}
      theme={themeState}
      accesibilitySize={accesibilitySizeState}
    >
      <Seo title={data.seo_title} desc={data.seo_description} />
      <Header/>
      <main id="app" className="productDetailPage" role="main">
        <Hero
          className="productDetailPage_hero"
          name={data?.title_name}
          phrase={data?.title_phrase}
          description={data?.description_2}
          ctaText={data?.cta_text}
          image={data?.background_image}
          setShowModal={setShowModal}
        />
        {data.body.length > 0 &&
          data.body.map((section, sectionIndex) => {
            switch (section.slice_type) {
              case "dual_content":
                return (
                  <section className="productDetailPage_dualContent">
                    <HomeDualContent
                      key={`dual_content${sectionIndex}`}
                      data={section?.primary}
                    />
                  </section>
                )
              case "benefits":
                const benefits = section
                return (
                  <section className="productDetailPage_benefits">
                    <Benefits
                      newTitle={benefits.primary.benefit_title_2}
                      subtitle={benefits.primary.benefit_subtitle}
                      list={benefits.items}
                    />
                  </section>
                )
              case "features":
                const features = section
                return (
                  <section className="productDetailPage_features">
                    {features.items.map((feature, featureIndex) => (
                      <Feature
                        key={`feature-${featureIndex}`}
                        className="productDetailPage_features_item"
                        title={feature.feature_title}
                        description={feature.feature_description}
                        items={feature.feature_items}
                        image={feature.feature_image}
                        imageLeft={featureIndex % 2 !== 0}
                      />
                    ))}
                  </section>
                )
              case "feature_module":
                const featureModule = section
                return (
                  <FeatureModule 
                     title={featureModule.primary.feature_module_title} 
                     description={featureModule.primary.feature_module_description}
                     items={featureModule.items}
                     imageLeft={sectionIndex % 2 === 0}
                  />
                )
              case "modules":
                const module = section
                return (
                  <Module
                    title={module.primary.module_title}
                    list={module.items}
                  />
                )
              case "architecture":
                const architecture = section
                return (
                  <Architecture
                    title={architecture.primary.architecture_title}
                    list={architecture.items}
                  />
                )
              case "contact":
                const contact = section?.primary;
                return (
                  <FormContact
                    id="contacto"
                    className="productDetailPage_contact"
                    category={contact?.contact_category}
                    question={contact?.contact_question}
                    ctaText={contact.contact_main_button_text}
                    description={contact?.contact_description}
                    uid={`contact-${uid}`} />
                )
              case "brochure":
                const brochure = section
                return (
                  <section className={cx("modal", showModal && "modal__show")}>
                    <RegisterForm
                      title={brochure.primary.brochure_title.text}
                      confirmButtonText={
                        brochure.primary.brochure_main_button_text
                      }
                      cancelButtonText={
                        brochure.primary.brochure_cancel_button_text
                      }
                      onCancel={() => {
                        setShowModal(false)
                      }}
                      uid={`register-brochure-${uid}`}
                    />
                  </section>
                )
              default:
                break
            }
          })}
      </main>
      <Footer />
    </Layout>
  )
}

export default ProductDetail

export const pageQuery = graphql`
  query ProductDetail($lang: String!, $uid: String!) {
    prismicProductDetail(lang: { eq: $lang }, uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        title_name {
          richText
        }
        title_phrase {
          richText
        }
        description_2 {
          richText
        }
        cta_text
        background_image {
          alt
          url
        }
        body {
          ... on PrismicProductDetailDataBodyDualContent {
            id
            slice_type
            primary {
              dual_content_category
              dual_content_title_position
              dual_content_title {
                richText
              }
              dual_content_subtitle {
                text
              }
              dual_content_description {
                richText
              }
              dual_content_cta_type
              dual_content_cta {
                richText
              }
              dual_content_image {
                alt
                url
              }
            }
          }
          ... on PrismicProductDetailDataBodyFeatureModule {
            id
            slice_type
            primary {
              feature_module_title {
                richText
              }
              feature_module_description
            }
            items {
              module_name
              module_category
              module_title {
                richText
              }
              module_description
              module_items {
                richText
              }
              module_image {
                url
                alt
              }
            }
          }
          ... on PrismicProductDetailDataBodyBenefits {
            id
            slice_type
            primary {
              benefit_title_2 {
                richText
              }
              benefit_subtitle
            }
            items {
              benefit_icon {
                alt
                url
              }
              benefit_name
              benefit_description
            }
          }
          ... on PrismicProductDetailDataBodyFeatures {
            id
            slice_type
            items {
              feature_image {
                alt
                url
              }
              feature_title
              feature_description
              feature_items {
                richText
              }
            }
          }
          ... on PrismicProductDetailDataBodyModules {
            id
            slice_type
            primary {
              module_title {
                text
              }
            }
            items {
              submodule_icon {
                alt
                url
              }
              submodule_name
            }
          }
          ... on PrismicProductDetailDataBodyArchitecture {
            id
            slice_type
            primary {
              architecture_title {
                text
              }
            }
            items {
              architecture_icon {
                alt
                url
              }
              architecture_name
              architecture_description
            }
          }
          ... on PrismicProductDetailDataBodyContact {
            id
            slice_type
            primary {
              contact_category
              contact_question {
                richText
              }
              contact_description {
                richText
              }
              contact_main_button_text
              contact_title {
                text
              }
            }
          }
          ... on PrismicProductDetailDataBodyBrochure {
            id
            slice_type
            primary {
              brochure_title {
                text
              }
              brochure_main_button_text
              brochure_cancel_button_text
            }
          }
        }
      }
    }
  }
`
