import React, { useEffect, useRef, useState } from "react"
import cx from "classnames"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import HomeSubtitleUnderline from "../../home-subtitle-underline/HomeSubtitleUnderline"
import Tabs from "./components/tabs/Tabs"
import ModuleItem from "./components/module-item/ModuleItem"
import "swiper/css";
import "swiper/css/pagination";
import "./featureModule.scss"

const FeatureModule = ({ title, description, items, imageLeft }) => {
  const [componentType, setComponentType] = useState('tabs');
  const featureModuleSwiperRef = useRef();

  const setComponent = () =>
  setComponentType(
      window.innerWidth <= 575.98
        ? 'swiper'
        : 'tabs'
    )

  useEffect(() => {
    setComponent();
    window.addEventListener(`resize`, setComponent);
  }, [])

  return (
    <section className="featureModule">
      <HomeSubtitleUnderline>{title?.richText[0]}</HomeSubtitleUnderline>
      <p className="featureModule_description">{description}</p>
      {componentType === 'tabs' && <Tabs
        items={items.map(
          (
            {
              module_name,
              module_title,
              module_description,
              module_items,
              module_image,
              module_category
            },
            _itemIndex
          ) => {
            return {
              label: module_name,
              children: (
                <ModuleItem
                  key={`featureModuleItem_${_itemIndex}`}
                  category={module_category}
                  title={module_title}
                  description={module_description}
                  items={module_items}
                  image={module_image}
                  imageLeft={imageLeft}
                />
              ),
            }
          }
        )}
      />}
      {componentType === 'swiper' && (
        <Swiper
          autoHeight
          className="featureModule_swiper"
          onBeforeInit={(swiper) => {
            featureModuleSwiperRef.current = swiper;
          }}
          pagination={{
            bulletClass: 'swiper-pagination-bullet swiperBullet',
            horizontalClass: 'swiperPagination'
          }}
          modules={[Pagination]} 
          spaceBetween={50}
          slidesPerView={1}
        >
          {items?.length > 1 && <div className="swiperButtons">
            <button
              className="swiperButtons_item"
              onClick={() => featureModuleSwiperRef.current?.slidePrev()}>
              <img className="chevronIcon" src="/img/chevron-right-bold.svg" alt="prev" />
            </button>
            <button
              className="swiperButtons_item"
              onClick={() => featureModuleSwiperRef.current?.slideNext()}>
              <img className="chevronIcon" src="/img/chevron-right-bold.svg" alt="next" />
            </button>
          </div>}
          {items?.map(({
              module_title,
              module_description,
              module_items,
              module_image,
              module_category,
              module_name,
            }, index) => (
            <SwiperSlide>
              <ModuleItem
                className={cx(items?.length > 1 && 'swiperModuleItem')}
                key={`featureModuleItem_${index}`}
                category={module_category}
                title={module_title}
                description={module_description}
                items={module_items}
                image={module_image}
                imageLeft={imageLeft}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </section>
  )
}

export default FeatureModule
